
import axios from 'axios'

const speakerId = "6cd72b18-9708-4e9c-9e73-08d895f6a24f";
const speakerQuery = `{documents(modelId:%20%22` + speakerId + `%22,%20count:50){documents{id,tagList,fieldValues},linkedDocuments{key,value{fieldValues}},totalDocuments}}`;
const speakerUrl = `https://digitaliceland.azurewebsites.net/graphql?query=${speakerQuery}`;

const breakoutQuery = `{documents(modelId:%20%22c4deb793-7016-4d7f-1e8a-08d850bfd991%22,%20count:50){documents{id,tagList,fieldValues},linkedDocuments{key,value{fieldValues}},totalDocuments}}`
const breakoutUrl = `https://advaniaconferenceweb.azurewebsites.net/graphql?query=${breakoutQuery}`

export const getSpeakers = () => {
	return axios.get(speakerUrl)
	.then(response => {
		//console.log(response.data.data.documents.documents)
		return response.data.data.documents.documents.map(x => x.fieldValues)
	})
}

export const getSideSpeakers = () => {
	return axios.get(breakoutUrl)
	.then(response => {
		return response.data.data.documents.documents.map(x => x.fieldValues);
	})
}